import { ControllerParams } from '@wix/yoshi-flow-editor';
import { AnnotoState, createAnnotoHandlers } from './annoto';
import { createLiveHandlers } from './live';
import { createPricingPlanHandlers, PricingPlanState } from './pricing-plan';
import { Handlers } from './handlers';

export type ControllerState = AnnotoState & PricingPlanState;
export type HandlersWithState = {
  handlers: Handlers;
  getControllerState: () => ControllerState;
  controllerState: ControllerState;
};

export function createHandlers(context: ControllerParams): HandlersWithState {
  let controllerState: ControllerState = {};
  const { controllerConfig } = context;
  const setState = (state: Partial<ControllerState>) => {
    controllerState = {
      ...controllerState,
      ...state,
    };
    controllerConfig.setProps({ controllerState });
  };

  return {
    controllerState,
    getControllerState: () => controllerState,
    handlers: {
      ...createAnnotoHandlers(setState, context),
      ...createLiveHandlers(context),
      ...createPricingPlanHandlers(setState, context),
    },
  };
}
